$form-button-radius: 0px;
$form-button-border-width: 2px;

$form-accent-button-radius: 20px;
$form-accent-button-border-width: 0;

$footer-form-scroll-button-radius-top-left: $form-button-radius;
$footer-form-scroll-button-radius-top-right: $form-button-radius;
$footer-form-scroll-button-radius-bottom-left: 0;
$footer-form-scroll-button-radius-bottom-right: 0;

$search-banner-form-field-radius-top-left: $form-fields-radius;
$search-banner-form-field-radius-top-right: $form-fields-radius;
$search-banner-form-field-radius-bottom-left: $form-fields-radius;
$search-banner-form-field-radius-bottom-right: $form-fields-radius;

$footer-content-button-border-width: $form-button-border-width;
$footer-content-button-radius: $form-button-radius
