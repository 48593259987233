.disclaimer {
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: $disclaimer-background;
    padding: 30px 0;
    display: none;

    a {
        display: inline-block;
    }

    .btn {
        margin-top: 45px;
        &.btn-primary {
            border: 1px solid $disclaimer-primary-button-border;
            color: $disclaimer-primary-button-text;
            background: $disclaimer-primary-button-background;

            &:not(:disabled):not(.disabled):hover {
                border: 1px solid $disclaimer-primary-button-border-hover;
                color: $disclaimer-primary-button-text-hover;
                background: $disclaimer-primary-button-background-hover;
            }

            &:not(:disabled):not(.disabled):active, &:focus {
                border: 1px solid $disclaimer-primary-button-border-active;
                color: $disclaimer-primary-button-text-active;
                background: $disclaimer-primary-button-background-active;
            }
        }
        &.btn-secondary {
            border: 1px solid $disclaimer-secondary-button-border;
            color: $disclaimer-secondary-button-text;
            background: $disclaimer-secondary-button-background;

            &:hover {
                border: 1px solid $disclaimer-secondary-button-border-hover;
                color: $disclaimer-secondary-button-text-hover;
                background: $disclaimer-secondary-button-background-hover;
            }
            &:not(:disabled):not(.disabled):active, &:focus {
                border: 1px solid $disclaimer-secondary-button-border-active;
                color: $disclaimer-secondary-button-text-active;
                background: $disclaimer-secondary-button-background-active;
            }
        }
    }

    .text {
        font-size: 1.2rem;
        line-height: 30px;
        text-align: left;
        color: $disclaimer;
        width: 70%;
        margin: 0 auto;

        @media only screen and (max-width:991px) {
            font-size: 1rem;
            width: 90%;
            line-height: 1.5rem;
        }
    }

    .buttons {
        text-align: center;
    }
}

#rgpd-infos {
    position: fixed;
    z-index: 30;
    bottom: 0;
    .btn {
        margin-top: 25px;
    }
}

#captcha-disclaimer {
    margin-top: 5px;
    text-align: left;
}
#registrationAssociate .licence-agreement .custom-control-label{
    margin-bottom: 0;
}
#old-browsers {
    position: fixed;
    z-index: 999;
}

#maintenance-infos {
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    z-index: 999;
    .text {
        text-align: center;
        margin-bottom: 0;
        i {
            font-size: 1.2em;
            position: absolute;
            right: 20px;
            top: 16px;
            cursor: pointer;
        }
    }
}
