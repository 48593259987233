.readMore {

    .multiline {
        overflow: hidden;
        position: relative;
        transition: height 1s ease;

        &:before {
            content: "";
            transition: opacity .5s ease;
            background: linear-gradient(rgba(255,255,255, 0), #FFFFFF);
            opacity: 0;
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
        }

        &.closed {
            &:before {
                opacity: 1;
                height: 100%;
            }
        }
    }

    .inline {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .viewMore {
        margin: 10px 0 15px 0;
        font-weight: bold;
        text-align: center;

        a {
            color: $link;

            &:hover {
                color: $link-hover;
            }
            &:active {
                color: $link-active;
            }
        }
    }
}
