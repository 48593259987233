.optional {
    color: $form-field-optional;
    white-space: nowrap;
    &:before {
        content: '-';
        padding: 0 3px;
    }
    &.block {
        display: block;

        &:before {
            content: '';
            padding: 0;
        }
    }
}
