#pagination{
    display: inline-block;

    li {
        display: inline-block;
        &.page-item {
            border:none !important;
            display: none !important;
            white-space: nowrap;
            &.first,
            &.prev,
            &.next,
            &.last {
                display: inline-block !important;
                .page-link {
                    color: transparent;
                    position: relative;
                    border:none;
                    font-size: 0.75em;
                    padding: 0;
                    width: 15px;
                    &:before {
                        color: $pagination-navigation;
                        font-family: 'fontAwesome';
                        font-size: 1.786em;
                    }
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
            &.prev, &.next {
                .page-link {
                    &:before {
                        margin-left: 4px;
                    }
                }
            }
            &.first, &.last {
                .page-link {
                    &:before {
                        margin-left: 2px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1150px) {
        .page-content {
            padding: 0;
            #current-page {
                width: 25px;
                @media only screen and (max-width:650px) {
                    width: 35px;
                    margin-top: 8px;
                }
            }
        }
    }

    @media only screen and (max-width:991px) {
        li.page-item.first, li.page-item.prev, li.page-item.next, li.page-item.last {
            a.page-link {
                width: 12px;
            }
        }
    }
    .page-content{
        display: inline-block;
        padding: 0 10px;
        font-weight: $weight-light;

        label {
            font-weight: $weight-light;
            cursor: pointer;
        }
        #current-page {
            padding: 0;
            line-height: 24px;
            border: 1px solid $pagination-navigation-page-field-border;
            width: 35px;
            height: 24px;
            margin-left: 4px;
            margin-right: 4px;
            text-align: center;
            background: none;
            font-weight: $weight-light;
            color: $pagination-navigation-page-field;
            background-color: $pagination-navigation-page-field-background;

            -moz-appearance: textfield;
            border-radius: 4px;
            outline: none;
            box-shadow: none;
        }
    }
}
