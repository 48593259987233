.section-collaborative-indexation {
    @media only screen and (max-width:$editorial-container-width) {
        padding-left: 0;
        padding-right: 0;
    }
    form {
        background-color: $search-form-nominative-background;
        padding: 26px 30px 50px 30px;
        line-height: 30px;
        @media only screen and (max-width:$editorial-container-width) {
            padding: 30px 39px 20px 39px;
        }
        div {
            float:left;
            margin-bottom: 0px;
            &.nominative-input {
                width: 44%;
                margin-right: 2%;
                @media only screen and (max-width:$editorial-container-width) {
                    width: 100%;
                    margin-right: 0;
                }
                label {
                    color: $search-form-nominative-label;
                    font-size: 14px;
                }
                input {
                    &.form-control {
                        color: $search-form-nominative-field;
                        background-color: $search-form-nominative-field-background;
                        border: 1px solid $search-form-nominative-field-border;
                    }
                }
            }
            &.nominative-submit {
                width: 8%;
                text-align: right;
                margin-top: 32px;
                button {
                    font-size: 0.8em;
                }
                @media only screen and (max-width:$editorial-container-width) {
                    width: 100%;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        }
    }
}
