#actionbar {
    float: right;
    right: 0px;
    height: 40px;
    position: relative;
    z-index: 990;

    &.fixed {
        position: fixed;
    }

    @media only screen and (max-width:650px) {
        position: relative;
    }

    @media only print {
        display: none;
    }

    .actionbar-container
    {
        box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
        display: inline-block;

        .actionbar-toggle {
            float: right;
            background: $action-bar-button-icon-background;
            font-size: 2em;
            height: 40px;
            padding: 0;
            border-radius: 0;
            width: 40px;
            text-align: center;
            line-height: initial;

            .point {
                width: 6px;
                height: 6px;
                margin: 2px auto;
                @include border-radius(1px);
                background-color: $action-bar-button-icon;
            }

            &.active {
                background: $action-bar-button-icon-background-active;
                .point {
                    background-color: $action-bar-button-icon-active;
                }
            }

            &:hover {
                cursor: pointer;
                background: $action-bar-button-icon-background-hover;
                .point {
                    background-color: $action-bar-button-icon-hover;
                }
            }

            &.disabled {
                background: $action-bar-button-icon-background-disabled;
                .point {
                    background-color: $action-bar-button-icon-disabled;
                }
            }

            i {
                vertical-align: middle;
            }
        }

        .actionbar-actions {
            right: 40px;
            height: 40px;
            display: flex;
            flex-direction: row-reverse;

            .actionbar-action {
                float: right;
                background: $action-bar-icon-background;
                color: $action-bar-icon;
                font-size: 1.4em;
                width: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 0;
                padding: 0;

                &:hover {
                    cursor: pointer;
                    background: $action-bar-icon-background-hover;
                    color: $action-bar-icon-hover;
                }

                &:active {
                    background: $action-bar-icon-background-active;
                    color: $action-bar-icon-active;
                }

                &.disabled {
                    cursor: initial;
                    background: $action-bar-icon-background-disabled;
                    color: $action-bar-icon-disabled;
                }

                &.bookmarked {
                    color: $action-bar-icon-bookmarked;
                    background: $action-bar-icon-bookmarked-background;

                    &:hover {
                        background: $action-bar-icon-bookmarked-background-hover;
                        color: $action-bar-icon-bookmarked-hover;
                    }

                    &:active {
                        background: $action-bar-icon-bookmarked-background-active;
                        color: $action-bar-icon-bookmarked-active;
                    }
                }
            }
        }

        .hidden {
            display: none;
        }
    }
}

#display-results {
    #actionbar {
        .actionbar-container {
            box-shadow: none;
            .actionbar-toggle {
                padding: 2px 0 0 2px;
            }
            .actionbar-actions, .actionbar-toggle {
                border-left: 1px solid rgba(63, 63, 68, 0.05);
            }
            .actionbar-toggle.active {
                border-left: none;
            }
        }
    }
}
