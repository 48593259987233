.section-search {
    font-size: 0.9rem;
    position: relative;
    @include width-height(100%,126px);
    background-color: $search-banner-background;
    background-image:url('../../common/scss/img/search/banner.png');
    @include bgCover();
    @media only screen and (max-width:650px) {
        height: inherit;
        background-image:none !important;
    }
    @media only screen and (max-width:1600px) {
        background-size: auto;
    }
    .search-content {
        form#search
        {
            display: flex;
            height: initial;
        }
        @include absolute-topcalcleftcalc(28.5px,307px);
        @media only screen and (max-width:650px) {
            width:100%;
            height: 54px;
            position: relative;
            top:inherit;
            left: inherit;
        }
        label {
            display: none;
        }
        button {
            &.round {
                @media only screen and (max-width:650px) {
                    display: none;
                }
            }
        }
        .search-content-field {
            display: flex;
            align-items: center;
            background-color: $search-banner-field-background;
            border: 1px solid $search-banner-field-border;
            overflow: hidden;

            @media only screen and (min-width:650px) {
                border-top-left-radius: $search-banner-form-field-radius-top-left;
                border-bottom-left-radius: $search-banner-form-field-radius-bottom-left;
            }

            #search-input {
                border: none;
                border-radius: 0;
                padding: 0;
            }
        }
        .clearfix {
            width:370px;

            @include box-shadow();
            padding-left: 10px;
            @media only screen and (max-width:650px) {
                @include padding-similarities(20px);
                @include width-heightlineheightsimilarities(100%, 52px);
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
            }
            i {
                color: $search-banner-field;
                display: block;
                font-family: 'fontawesome-webfont';
                font-weight: $weight-light;
                margin-right: 10px;
            }
            input {
                width: 90%;
                border: none;
                color: $search-banner-field !important;
                padding-left: 0;
                background-color: $search-banner-field-background !important;

                @media only screen and (max-width:650px) {
                    width: 85%;
                }

                &::placeholder {
                    color: $search-banner-field-placeholder !important;
                }
            }
        }
        .scope-select {
            position: relative;
            width: 170px;

            .btn, .dropdown-item {
                font-weight: $weight-regular;
            }
            .dropdown {

                .btn {
                    border-top-right-radius: $search-banner-form-field-radius-top-right;
                    border-bottom-right-radius: $search-banner-form-field-radius-bottom-right;
                }

                &.show {
                    .btn {
                        border-bottom-right-radius: 0px;
                    }
                }

                .filter-option {
                    font-weight: $weight-regular;
                }
            }

            @media only screen and (max-width:650px) {
                display:none;
            }
            .bootstrap-select {
                .selectpicker {
                    display: none;
                }
                button {
                    position: relative;
                    width: 100%;
                    font-size: 1em;
                    padding: 0.49em 1em;
                    border: none;
                    background-image: none;
                    color: $search-banner-dropdown-button;
                    background-color: $search-banner-dropdown-button-background;
                    text-align: left;
                    border-radius: 0 2px 2px 0px;
                    @include box-shadow();
                    &:focus {
                        border-color: inherit;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                    }
                    &:after {
                        position: absolute;
                        top: 16px;
                        right: 10px;
                    }
                }
                div.dropdown-menu {
                    overflow: inherit !important;
                    padding: 0;
                    margin: 0;
                    border: none;
                    border-radius: unset;
                    z-index: 900;
                    ul {
                        width: 170px;
                        padding: 0;
                        border-radius: unset;
                        border: none;
                        z-index: 900;
                        @include box-shadow();

                        li {
                            list-style: none !important;

                            &:last-child {
                                a {
                                    border-bottom: 1px solid $dropdown-menu-last-item-border-bottom;

                                    &:hover {
                                        border-bottom: 1px solid $dropdown-menu-last-item-border-bottom-hover;
                                    }
                                    &:active {
                                        border-bottom: 1px solid $dropdown-menu-last-item-border-bottom-active;
                                    }
                                    &.selected {
                                        border-bottom: 1px solid $dropdown-menu-last-item-border-bottom-selected;
                                    }
                                }
                            }

                            a {
                                display: block !important;
                                padding: 0.7em 1em;
                                font-size: 0.88em;
                                line-height: 1.1;
                                color: $search-banner-dropdown-menu-item;
                                background-color: $search-banner-dropdown-menu-item-background;
                                border: 1px solid $search-banner-dropdown-menu-border;
                                border-bottom: 1px solid $search-banner-dropdown-menu-item-border-bottom;
                                border-top: none;

                                &:hover {
                                    color: $search-banner-dropdown-menu-item-hover;
                                    background-color: $search-banner-dropdown-menu-item-background-hover;
                                    border: 1px solid $search-banner-dropdown-menu-border-hover;
                                    border-bottom: 1px solid $search-banner-dropdown-menu-item-border-bottom-hover;
                                    border-top: none;
                                }
                                &:active {
                                    color: $search-banner-dropdown-menu-item-active;
                                    background-color: $search-banner-dropdown-menu-item-background-active;
                                    border: 1px solid $search-banner-dropdown-menu-border-active;
                                    border-bottom: 1px solid $search-banner-dropdown-menu-item-border-bottom-active;
                                    border-top: none;
                                }
                                &.selected {
                                    color: $search-banner-dropdown-menu-item-selected;
                                    background-color: $search-banner-dropdown-menu-item-background-selected;
                                    border: 1px solid $search-banner-dropdown-menu-border-selected;
                                    border-bottom: 1px solid $search-banner-dropdown-menu-item-border-bottom-selected;
                                    border-top: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        button {
            &.round {
                margin-left: 30px;
            }
        }
    }
    .help-search {
        @include width-height(615px,30px);
        @include absolute-bottomleftcontent(20px,307px);
        @include flexbox;
        @include align-items(flex-start);
        line-height: initial;
        font-weight: $weight-medium;
        padding-top: 2px;
        @media only screen and (max-width:650px) {
            display: none;
        }
        i {
            display: block;
            float: left;
            font-family: 'fontawesome-webfont';
            font-size: 1rem;
            color: $search-banner-info;
        }
        a {
            float: left;
            color: $search-banner-info;
            font-size: 12px;
            margin-left:8px;
            padding-top: 1px;
        }
    }
}
