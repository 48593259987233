.modal {
    @include padding-similarities(8.5px);
    @media only screen and (max-width:650px) {
        @include padding-similarities(0);
    }
    .modal-dialog {
        max-width: 1360px;
        height: 75vh;
        overflow-y: initial !important;
        z-index: 2000;
        @media only screen and (max-width:650px) {
            padding-top: 35px;
            @include margin-similarities(0);
            height: 100vh;
        }
        .modal-header {
            font-weight: $weight-bold;
            border:none;

            .title {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .modal-content {
            @include border-radius(0);
            height: 100%;
            .modal-header {
                font-weight: $weight-medium;
                border:none;
                box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
                line-height: 30px;
                padding-left:34px;
                button.close {
                    opacity: unset;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 0;
                    padding: 0;
                    color: $modal-close;
                    &:hover {
                        background-color:transparent;
                    }
                    span {
                        font-size:1.75em;
                    }
                }
            }
            .modal-body {
                padding:0;
                overflow-y: auto;
            }
        }
    }
}
