header, footer {
    .container {
        &.institutional {
            .row {

                .header-footer-col {
                    display: inline-flex;
                    align-items: center;

                    &:not(:first-child) {
                        &.header-footer-align-left {
                            padding-left: 7px;
                        }
                    }

                    &:not(:last-child) {
                        &.header-footer-align-right {
                            padding-right: 7px;
                        }
                    }

                    &.header-footer-align-center {
                        justify-content: center;
                        padding: 0 7px;
                    }

                    &.header-footer-align-right {
                        justify-content: flex-end;
                    }

                    @media only screen and (max-width:768px) {
                        margin-bottom: 20px;

                        &.header-footer-align-center, &.header-footer-align-right {
                            justify-content: flex-start;
                        }
                    }

                    @media only screen and (max-width:768px) {
                        margin-bottom: 20px;

                        &.header-footer-align-center, &.header-footer-align-right {
                            justify-content: flex-start;
                        }
                    }

                    .heading {
                        margin-right: 20px;
                    }

                    .links {
                        display: inline-block;

                        li {
                            display: inline-block;

                            &.link {
                                margin: 0 20px 0 0;

                                &.icon-only {
                                    margin: 0 10px 0 0;
                                }

                                &:last-child
                                {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
