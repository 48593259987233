#notice-content, #classification-branch {
    .content {
        span {
            &.bold {
                font-weight: $record-weight-bold;
            }
            &.italic {
                font-style: italic;
            }
            &.underline {
                text-decoration: underline;
            }
            &.super {
                vertical-align: super;
                font-size: 0.7em;
            }
            &.sub {
                vertical-align: sub;
                font-size: 0.7em;
            }
        }

        ul {
            li {
                list-style: square;
                list-style-position: inside;
            }
        }
    }
}
