.confirmation.popover {

    .popover-header {
        color: $confirmation-header;
        background-color: $confirmation-header-background;
        font-weight: $weight-medium;
    }

    .popover-body {
        color: $confirmation-body;

        .confirmation-buttons {
            margin-top: 1rem;

            .btn-group {
                a {
                    margin-right: 3px;
                }

                & > .btn {
                    border-radius: 0.25rem;
                }

                & > .btn:first-child {
                    margin-right: 15px;
                }

                & > .btn:last-child {
                    margin-right: 0;
                }

                i {
                    margin-right: 5px;
                }
            }
        }
    }
}
