footer {

    clear: both;

    .container {
        line-height: 1.5;
        font-family: $font-primary;
        font-weight: $weight-regular !important;

        @include flexbox();
        @include justify-content(center);

        &.default {
            background-color: $footer-content-background;
            border-top: 1px solid $footer-content-border-top;
        }

        &.institutional {
            background-color: $footer-institutional-background;
            border-top: 1px solid $footer-institutional-border-top;
        }

        .row {
            justify-content: center;

            @media only screen and (max-width:1140px) {
                justify-content: normal;
            }
        }

        .icon-list {
            display: flex;
            flex-direction: row;

            .link {
                margin-right: 10px;
            }
        }

        .links {
            .link {
                margin-bottom: 5px;
            }
        }

        img {
            display: inline-block;
        }

        .institutional {

            background-color: $footer-institutional-background;
            border-top: 1px solid $footer-institutional-border-top;

            .row {
                width: $footer-institutional-width;
                padding: 10px 20px;
            }
        }

        &.default {
            .row {
                width: $footer-content-width;
                color: $footer-content-text;
                padding: 30px 20px;
                @media only screen and (max-width:768px) {
                    padding-top: 0;
                    padding-left: 30px;
                }

                .header-footer-col {
                    padding: 0 10px;

                    @media only screen and (max-width:768px) {
                        margin-top: 30px;
                        padding-left: 0px;
                        padding-right: 0px;
                    }

                    &.header-footer-align-left {
                        text-align: left;
                    }

                    &.header-footer-align-center {
                        text-align: center;
                    }

                    &.header-footer-align-right {
                        text-align: right;
                    }

                    &.header-footer-align-center, &.header-footer-align-right {
                        @media only screen and (max-width:768px) {
                            text-align: left;
                        }
                    }

                    .links {
                        .link {
                            i {
                                vertical-align: middle;
                            }
                        }
                    }

                    .icon-list {
                        margin-bottom: 10px;

                        .link {
                            margin-right: 10px;
                        }
                    }

                    i {
                        margin-right :8px;
                    }

                    a {
                        color: $footer-content-link;

                        &:hover, &:focus {
                            color: $footer-content-link-hover;
                        }

                        &:active {
                            color: $footer-content-link-active;
                        }

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }

                    p {
                        margin-bottom: 10px;
                    }

                    .heading {
                        font-weight: $weight-medium;
                        color: $footer-content-heading;
                        display: block;
                        margin-bottom: 11px;

                        &:after {
                            content: "\00a0";
                        }
                    }

                    .subheading {
                        font-weight: $weight-medium;
                        color: $footer-content-heading;
                        display: block;
                    }

                    .title {
                        font-weight: $weight-medium;
                        color: $footer-content-title;
                        display: block;
                        margin-top: 10px;
                    }

                    .btn {
                        background-color: $footer-content-button-background;
                        border-color: $footer-content-button-border;
                        color: $footer-content-button-text;
                        white-space: normal;

                        &:hover, &:focus {
                            background-color: $footer-content-button-background-hover;
                            border-color: $footer-content-button-border-hover;
                            color: $footer-content-button-text-hover;
                        }

                        &:active {
                            background-color: $footer-content-button-background-active;
                            border-color: $footer-content-button-border-active;
                            color: $footer-content-button-text-active;
                        }
                    }
                }
            }
        }

        &.institutional {

            .row {
                width: $footer-institutional-width;
                color: $footer-institutional-text;
                padding: 10px 20px;

                @media only screen and (max-width:768px) {
                    padding-left: 30px;
                    padding-top: 30px;
                }

                .link {
                    margin-right: 10px;
                }

                .header-footer-col {


                    &.header-footer-align-left, &.header-footer-align-center {
                        @media only screen and (max-width:768px) {
                            padding-left: 0px;
                        }
                    }

                    a {
                        color: $footer-institutional-link;

                        &:hover, &:focus {
                            color: $footer-institutional-link-hover;
                        }

                        &:active {
                            color: $footer-institutional-link-active;
                        }
                    }

                    .heading {
                        font-weight: $weight-regular;
                        color: $footer-institutional-heading;
                    }
                }
            }
        }
    }
}
