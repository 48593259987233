.alert-info {
    background-color: #cff4fc;
    border-color: #9eeaf9;
    color: #055160;

    .close {
        color: #055160;
    }
}

.alert-success {
    background-color: #d1e7dd;
    border-color: #a3cfbb;
    color: #0a3622;

    .close {
        color: #0a3622;
    }
}

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffe69c;
    color: #664d03;

    .close {
        color: #664d03;
    }
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f1aeb5;
    color: #58151c;

    .close {
        color: #58151c;
    }
}
