.enhanced-select {
    padding-left: 0;
    padding-right: 0;
    border-width: 1px;

    @media only screen and (max-width: 991px) {
        max-width: calc(100% - 10px);
    }

    .enhanced-select-component {
        .enhanced-select__control {
            border: 1px solid $form-multiselect-tags-container-border;
            border-radius: $form-fields-radius;
            min-height: unset;
            .enhanced-select__value-container {
                background-color: $form-multiselect-tags-container-background;
                border-radius: $form-fields-radius 0 0 $form-fields-radius;
                input {
                    height: unset !important;
                }

                .enhanced-select__multi-value {
                    line-height: 1.1;
                    padding: 4px;
                    background-color: $form-multiselect-tag-background;
                    border-radius: 25px;
                    &.hover {
                        background-color: $form-multiselect-tag-background-hover;
                        .enhanced-select__multi-value__label {
                            color: $form-multiselect-tag-hover;
                        }
                        .enhanced-select__multi-value__remove {
                            &:before, &:after {
                                background-color: $form-multiselect-tag-hover;
                            }
                        }
                    }
                    &.active {
                        background-color: $form-multiselect-tag-background-active;
                        .enhanced-select__multi-value__label {
                            color: $form-multiselect-tag-hover;
                        }
                        .enhanced-select__multi-value__remove {
                            &:before, &:after {
                                background-color: $form-multiselect-tag-hover;
                            }
                        }
                    }
                    .enhanced-select__multi-value__label {
                        font-size: inherit;
                        color: $form-multiselect-tag;
                        padding: 0 0 0 6px;
                        width: calc(100% - 15px);
                    }
                    .enhanced-select__multi-value__remove {
                        position: relative;
                        background-color: transparent;
                        color: $form-multiselect-tag-remove;
                        width: 20px;
                        &:hover {
                            cursor: pointer;
                            background-color: $form-multiselect-tag-background-hover;
                        }
                        &:active {
                            background-color: $form-multiselect-tag-background-active;
                        }
                        &:before, &:after {
                            position: absolute;
                            background-color: $form-multiselect-tag;
                            left: 9px;
                            height: 13px;
                            width: 3px;
                            content: "";
                            &:hover, &:active {
                                background-color: $form-multiselect-tag-hover;
                            }
                        }
                        &:before {
                            transform: rotate(-45deg);
                        }
                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }

                .enhanced-select__placeholder {
                    color: $form-multiselect-tags-container-placeholder;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 96%;
                    white-space: nowrap;
                }
            }
            .enhanced-select__indicators {
                background-color: $form-multiselect-tags-container-background;
                border-radius: 0 $form-fields-radius $form-fields-radius 0;
                align-items: unset;

                .enhanced-select__clear-indicator {
                    position: relative;
                    top: 6px;
                    right: -50px;
                    background-color: $form-multiselect-tags-clear-background;
                    @include border-radius(10px);
                    width: 21px;
                    height: 21px;
                    &:hover {
                        cursor: pointer;
                    }
                    &:before, &:after {
                        position: absolute;
                        background-color: $form-multiselect-tag;
                        top: 4px;
                        left: 9px;
                        height: 13px;
                        width: 3px;
                        content: "";
                    }
                    &:before {
                        transform: rotate(-45deg);
                    }
                    &:after {
                        transform: rotate(45deg);
                    }
                    &:hover {
                        background-color: $form-multiselect-tags-clear-background-hover;
                        &:before, &:after {
                            background-color: $form-multiselect-tags-clear-hover;
                        }
                    }
                    &:active {
                        background-color: $form-multiselect-tags-clear-background-active;
                        &:before, &:after {
                            background-color: $form-multiselect-tags-clear-active;
                        }
                    }
                }
                .enhanced-select__dropdown-indicator {
                    width: 25px;
                    &:before {
                        position: absolute;
                        top: 17px;
                        right: 10px;
                        width: 0;
                        height: 0;
                        content: '';
                        transform: rotate(0) translate3d(0, -50%, 0);
                        border-width: 4px 4px 0;
                        border-style: solid;
                        border-color: $form-multiselect-tags-container-placeholder transparent transparent;
                    }
                }
                .enhanced-select__indicator-separator {
                    display: none;
                }
            }
        }
        .enhanced-select__control--is-focused {
            box-shadow: unset;
            border: 1px solid $form-multiselect-tags-container-border-focus;
        }
        .enhanced-select__control--menu-is-open {
            .enhanced-select__indicators {
                .enhanced-select__dropdown-indicator {
                    &:before {
                        border-width: 0 4px 4px;
                        border-color: transparent transparent $form-multiselect-tags-container-placeholder;
                    }
                }
            }
        }
        .enhanced-select__menu {
            margin-top: 0;
            margin-bottom: 0;
            border: 1px solid $form-multiselect-options-border;
            box-shadow: unset;
            border-radius: 4px;
            .list > div {
                border-radius: 4px;
            }
            .enhanced-select__option {
                color: $form-multiselect-options;
                background-color: $form-multiselect-options-background;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    color: $form-multiselect-options-hover;
                    background-color: $form-multiselect-options-background-hover;
                }
                &:active {
                    color: $form-multiselect-options-active;
                    background-color: $form-multiselect-options-background-active;
                }
            }
            .enhanced-select__option--is-focused {
                color: $form-multiselect-options-hover;
                background-color: $form-multiselect-options-background-hover;
            }
        }
    }
}
