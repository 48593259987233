$color-1: #A65C02;
$color-2: #2E6471;
$color-3: #1D444E;
$color-4: #D9D0C0;
$color-5: #177E8F;
$color-6: #2E6471;
$color-7: #1D444E;
$color-8: #D9D0C0;
$color-9: transparent;
$color-10: #FFFFFF;
$color-11: #F8F8F4;
$color-12: #F4F2E6;
$color-13: #EAE9E2;
$color-14: #908885;
$color-15: #504B49;
$color-16: #263238;
$color-17: #1C2529;
$color-18: #FFFFFF;

$color-103: #614B61;
$color-104: #C7C6C4;
