header {
    #main-header {
        @media only screen and (max-width: 1024px) {
            background: $responsive-header-background;
            border-bottom: $responsive-header-menu-border-bottom-width solid $responsive-header-menu-border-bottom;

            img {
                margin-top: 0px;
                max-height: 40px !important;
                padding: 0 50px;

                @media only screen and (max-width: 680px) {
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            #logo {
                margin: 0 auto;

                img {
                    &.logo-desktop {
                        display: none;
                    }
                    &.logo-mobile {
                        display: block;
                    }
                }
            }
        }

        #burger-main-menu, #burger-user-profile {
            @media only screen and (min-width: 1025px) {
                display: none;
            }

            position: absolute;

            &.left {
                left: 0;

                .toggle {
                    left: 15px;
                }

                .sidebar {
                    left: -1024px;
                }
            }

            &.right {
                right: 0;

                .toggle {
                    right: 20px;
                }

                .sidebar {
                    left: 1024px;
                }
            }

            .toggle {
                top: 12px;
                @include width-heightsimilarities (24px);
                cursor: pointer;
                float: none;
                padding: 0;
                position: absolute;
                z-index: 998;

                .icon-bar {
                    display: block;
                    @include width-height (24px, 4px);
                    margin-bottom: 4px;
                    background: $responsive-header-menu-icon;
                }

                &:active {
                    .icon-bar {
                        background: $responsive-header-menu-icon-active;
                    }
                }

                .user-profile-icon {
                    color: $responsive-header-menu-user-profile;
                    &:active {
                        color: $responsive-header-menu-user-profile-active;
                    }

                    font-family: FontAwesome;
                    display: block;
                    @include width-height (30px, 30px);
                    font-size: 30px;
                    margin-top: -5px;

                    &.logged-in {
                        &:after {
                            content: " ";
                            background: #7ed321;
                            position: absolute;

                            width: 10px;
                            height: 10px;
                            -webkit-border-radius: 10px;
                            -moz-border-radius: 10px;
                            border-radius: 10px;
                            right: -5px;
                        }
                    }
                }
            }

            .sidebar {
                background: $responsive-header-menu-background;
                @include width-height (100%, 250px);
                position: fixed;
                @include transition(all, 0.8s, ease-in);
                top: 45px;
                z-index: 999;
                opacity: 0.98;
                width: 100vw;
                height: 100vh;
                overflow: auto;
                padding-top: 30px;
                padding-bottom: 120px;

                .institutional-banner-burger {
                    background: $responsive-header-institutional-banner-background;
                    padding: 13px 0px;

                    .row {
                        margin-left: 0px;

                        ul {
                            display: block;
                            text-align: left;

                            li {
                                line-height: 20px;
                                margin: 0px;
                                text-align: left;
                                display: block;


                                a {
                                    text-transform: none;
                                    color: $responsive-header-institutional-banner-link;
                                }
                                &:active {
                                    a {
                                        color: $responsive-header-institutional-banner-link-active;
                                    }
                                }

                                &:disabled {
                                    a {
                                        color: $responsive-header-institutional-banner-link-disabled;
                                    }
                                }
                                text-transform: none;
                            }
                        }

                        .heading {
                            color: $responsive-header-institutional-banner-heading;
                        }

                        .header-footer-col {

                            margin-bottom: 10px;
                            padding: 0 13px;
                            display: block;

                            &.header-footer-align-center {
                                text-align: center;
                            }

                            &.header-footer-align-right {
                                text-align: right;
                            }

                            a {
                                display: inline-block;
                                font-weight: $weight-medium;
                                padding: 13px 0;

                                img {
                                    margin: 0;
                                    padding: 0;
                                }
                            }

                            .link {
                                &.icon-only {
                                    display: inline-flex;
                                }
                            }

                        }
                    }

                    .header-footer-align-right, .header-footer-align-center {
                        justify-content: flex-start;
                    }

                    .header-footer-align-left, .header-footer-align-right, .header-footer-align-center {
                        padding: 0;
                    }

                }

                ul a, .heading {
                    font-size: 1.429rem;
                }

                ul {
                    > li {
                        float: none;
                        cursor: pointer;

                        > a {
                            padding: 13px 13px 13px 28px;
                            margin: 0;
                            text-decoration: none;
                            color: $responsive-header-menu-item;
                        }

                        &:active:not(.submenu-container) {
                            > a {
                                color: $responsive-header-menu-item-active;
                            }
                        }

                        &:disabled:not(.submenu-container) {
                            > a {
                                color: $responsive-header-menu-item-disabled;
                            }
                        }

                        > ul {
                            > li {
                                line-height: 30px;
                                text-transform: none;
                                margin-top: 5px;

                                > a {
                                    padding: 0px 28px 0px 58px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    color: $responsive-header-submenu-item;
                                }

                                &:active {
                                    > a {
                                        color: $responsive-header-submenu-item-active;
                                    }
                                }

                                &:disabled {
                                    > a {
                                        color: $responsive-header-submenu-item-disabled;
                                    }
                                }

                                span {
                                    margin: 0;
                                    padding: 5px 0 0 0;
                                }
                            }
                        }
                    }

                }
            }

            .sidebartoggler {
                display: none;

                &:checked {
                    & + .page-wrap {
                        position: absolute;
                        left: 0;
                        width: 100vw;
                        background: red;

                        .sidebar {
                            left: 0;
                        }

                        .toggle {
                            height: 55px;
                            top: 0;
                            margin-top: -5px;
                            padding-top: 20px;
                        }

                        .page-content {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }

        #burger-main-menu {
            .sidebar {
                > ul {
                    > li {
                        margin: 0px 0px 20px 0px;

                        > a {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        #burger-user-profile {
            .sidebar {
                > ul {
                    > li {
                        margin: 0;

                        > a {
                            text-transform: none;
                        }

                        &.logout {
                            i {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
