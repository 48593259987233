button, input[type=submit], input[type=button] {
    position: relative;
    display: block;
    font-weight: $weight-medium;
    text-align: center;
    @include border-radius($form-button-radius);
    padding: 0.54em 1em;
    font-size: 14px;
    &:not(:disabled):not(.disabled):hover {
        cursor: pointer;
    }
    &:focus,
    &.active {
        outline: none;
    }
    &.accent {
        border-style: solid;
        border-width: $form-accent-button-border-width;
        @include border-radius($form-accent-button-radius);
        font-weight: $weight-regular;
        width: auto;
        font-size: 1em;
        display: inline-block;
        background-color: $accent-button-background;
        border-color: $accent-button-border;
        color: $accent-button-text;
        padding: 0.68em 1em;
        &:before {
            font-family: 'fontawesome-webfont';
        }

        &:hover {
            background-color: $accent-button-background-hover;
            border-color: $accent-button-border-hover;
            color: $accent-button-text-hover;
        }

        &:not(:disabled):not(.disabled):active, &:focus {
            background-color: $accent-button-background-active;
            border-color: $accent-button-border-active;
            color: $accent-button-text-active;
            box-shadow: none !important;
        }

        &:disabled {
            background-color: $accent-button-background-disabled;
            border-color: $accent-button-border-disabled;
            color: $accent-button-text-disabled;
        }

        &.round {
            @include border-radius(50%);
            @include width-heightsimilarities(35px);
            padding: 0;
        }
    }
}

.btn {
    padding: 0.54em 1em;
    font-size: 14px;
    line-height: 1.5;
    margin-right: 15px;
    font-weight: $weight-medium;
    border-width: $form-button-border-width;
    @include border-radius($form-button-radius);

    &:not(:disabled):not(.disabled):active, &:focus {
        box-shadow: none !important;
    }
}

.btn-primary {
    background-color: $primary-button-background;
    border-color: $primary-button-border;
    color: $primary-button-text;
    a {
        color: $primary-button-text;
    }

    &:hover {
        background-color: $primary-button-background-hover;
        border-color: $primary-button-border-hover;
        color: $primary-button-text-hover;
        a {
            color: $primary-button-text-hover;
        }
    }

    &:not(:disabled):not(.disabled):active, &:focus {
        background-color: $primary-button-background-active;
        border-color: $primary-button-border-active;
        color: $primary-button-text-active;
        a {
            color: $primary-button-text-active;
        }
    }

    &:disabled {
        background-color: $primary-button-background-disabled;
        border-color: $primary-button-border-disabled;
        color: $primary-button-text-disabled;
        a {
            color: $primary-button-text-disabled;
        }
    }
}

.btn-secondary {
    background-color: $secondary-button-background;
    border-color: $secondary-button-border;
    color: $secondary-button-text;
    a {
        color: $secondary-button-text;
    }

    &:hover {
        background-color: $secondary-button-background-hover;
        border-color: $secondary-button-border-hover;
        color: $secondary-button-text-hover;
        a {
            color: $secondary-button-text-hover;
        }
    }

    &:not(:disabled):not(.disabled):active, &:focus {
        background-color: $secondary-button-background-active;
        border-color: $secondary-button-border-active;
        color: $secondary-button-text-active;
        a {
            color: $secondary-button-text-active;
        }
    }

    &:disabled {
        background-color: $secondary-button-background-disabled;
        border-color: $secondary-button-border-disabled;
        color: $secondary-button-text-disabled;
        a {
            color: $secondary-button-text-disabled;
        }
    }
}
