@import "../../common/variables/index.scss";
@import "../../../../common/scss/mixins.scss";

#searchHelp {
    .modal-header {
        line-height: 1em;
    }
    #astuce {
        margin:20px;
        max-width: none;
        h1 {
            font-size: 2.857em;
            padding-bottom:30px;
        }
        & > ul {
            > li {
                list-style: none;
                line-height: initial;
            }
            .intitup {
                position: relative;
                border-bottom:1px solid #d9dbdb;
                font-weight: $weight-bold;
                font-size: 1.429em;
                margin-top:32px;
                cursor: pointer;
                &:before {
                    @include abs-topleftcontent(-10px, 28px);
                    color: $search-help-modal-title-glyph;
                    content:'-';
                    font-size: 1.714em;
                }
                &.collapsed {
                    &:before {
                        content:'+';
                    }
                }
                h3 {
                    font-size: 1em;
                    color: $search-help-modal-title;
                    display: block;
                    margin-bottom:13px;
                    margin-left:60px;
                    margin-right:15px;
                }
            }
            .opened {
                .content {
                    padding:20px 60px 20px 60px;
                    font-weight: $weight-regular;
                    line-height: 2em;
                    color: $search-help-modal-text;
                }
            }
        }
    }
}
