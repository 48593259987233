$footer-content-width: 1280px;

$footer-content-background: $color-10;
$footer-content-border-top: $color-17;

$footer-content-heading: $color-5;
$footer-content-text: $color-17;

$footer-content-link: $color-1;
$footer-content-link-hover: $color-2;
$footer-content-link-active: $color-3;

$footer-content-title: $color-17;

$footer-content-button-border: $color-1;
$footer-content-button-border-hover: $color-2;
$footer-content-button-border-active: $color-3;

$footer-content-button-text: $color-10;
$footer-content-button-text-hover: $color-10;
$footer-content-button-text-active: $color-10;

$footer-content-button-background: $color-1;
$footer-content-button-background-hover: $color-2;
$footer-content-button-background-active: $color-3;

$footer-content-hide-button: $color-10;
$footer-content-hide-button-background: $color-17;

$footer-institutional-width: 1280px;

$footer-institutional-background: $color-103;
$footer-institutional-border-top: $color-103;

$footer-institutional-heading: $color-10;
$footer-institutional-text: $color-10;

$footer-institutional-link: $color-10;
$footer-institutional-link-hover: rgba($color-10, .7);
$footer-institutional-link-active: $color-10;
