@media print {

    .no-print {
        display: none !important;
    }

    header,
    footer {
        display:none !important;
    }

    .container {
        display: block;
        max-width: auto;
        min-width: auto !important;
    }

    #editorial {
        * {
            page-break-inside: avoid;
        }
        .container {
            article {
                .main-article {
                    section {
                        width: auto !important;

                        &.section-tiles {
                            li {
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    #notice-complete, #finding-aid-archdesc {
        .container {
            .notice {
                .list-notice {
                    display: none;
                }
                .content-notice {
                    margin-left: 0 !important;
                    width: 100% !important;

                    .img-notice {
                        display: none;
                    }

                    .attribut-col {
                        max-width: 15%;
                    }

                    .content-col {;
                        flex: 0 0 85%;
                        max-width: 85%;
                    }

                    .collapse {
                        display: block;
                    }
                }
            }
        }
    }

    #display-results {
        .container {
            border-left: none !important;
        }
        .navigation {
            border-bottom: 1px solid $results-navigation-border-print;
            border-top: 1px solid $results-navigation-border-print;
        }

    }
}
