form {
    width: 100%;
    height: 100%;
}
input:not([type="button"]):not([type="submit"]), .form-control, textarea {
    padding: .43em 1em;
    line-height: 1.5;
    font-size: 14px;
    @include border-radius($form-fields-radius);

    &::placeholder {
        color: $form-field-placeholder;
    }

    &, &:focus {
        outline: none;
        box-shadow: none;
        background-color: $form-field-background;
        color: $form-field;
        border: 1px solid $form-field-border;
    }

    &:disabled {
        color: $form-field-disabled;
    }
}

select,
label {
    color: $form-field-label;
    margin:0;
    &:focus {
        outline: none;
    }

    &.disabled {
        color: $form-field-label-disabled;
    }
}
.info {
    color: $form-field-info;
}

[type=text],
[type=password],
[type=email],
[type=tel],
[type=number],
[type=search],
textarea.materialize-textarea {
    height: inherit;
    font-size: inherit;
    margin: 0;
    box-sizing: inherit;
    &:focus {
        &:not([readonly]) {
            border-bottom: none;
            box-shadow: none;
        }
    }
}
[type="number"] {
    &::-webkit-outer-adjust-hue-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-adjust-hue-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
}
.custom-control-label {
    &:before {
        border: 2px solid $form-checkbox-border;
        background-color: $form-checkbox-background;
    }
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $form-checkbox-border;
}

.custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background: $form-checkbox-background-checked;
    border-color: $form-checkbox-border-checked;
}

.custom-checkbox .custom-control-input:checked:hover ~ .custom-control-label:before {
    background: $form-checkbox-background-hover-checked;
    border-color: $form-checkbox-border-hover-checked;
}

.custom-control-input:disabled ~ .custom-control-label:before {
    background: $form-checkbox-background-disabled;
    border-color: $form-checkbox-border-disabled;
}

.custom-control-input:not(:checked):hover ~ .custom-control-label:before {
    background: $form-checkbox-background-hover;
    border-color: $form-checkbox-border-hover;
}

.custom-control-input:active ~ .custom-control-label:before {
    background: $form-checkbox-background-active;
    border-color: $form-checkbox-border-active;
}

