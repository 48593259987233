.record-actions {
    display: flex;

    .action {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        border-radius: 0;
        background-color: $record-actions-button-background;
        color: $record-actions-icon-color;
        &:hover, &:focus {
            background-color: $record-actions-button-background-hover;
            i {
                color: $record-actions-icon-color-hover;
            }
        }
        &:active {
            background-color: $record-actions-button-background-active;
            i {
                color: $record-actions-icon-color-active;
            }
        }
        &.disabled {
            background-color: $record-actions-button-background-disabled;
            color: $record-actions-icon-color-disabled;
            cursor: default;
            &:hover {
                background-color: $record-actions-button-background-disabled;
                i {
                    color: $record-actions-icon-color-disabled;
                }
            }
        }
    }
}
