$form-field-optional: $color-14;

$form-fields-radius: 0px;

$form-multiselect-tag: $color-10;
$form-multiselect-tag-background: $color-14;
$form-multiselect-tag-hover: $color-10;
$form-multiselect-tag-background-hover: $color-1;
$form-multiselect-tag-background-active: $color-3;
$form-multiselect-tag-remove: $color-10;
$form-multiselect-tags-clear-background: $color-14;
$form-multiselect-tags-clear: $color-10;
$form-multiselect-tags-clear-hover: $color-10;
$form-multiselect-tags-clear-active: $color-10;
$form-multiselect-tags-clear-background-hover: $color-1;
$form-multiselect-tags-clear-background-active: $color-3;
$form-multiselect-tags-container-placeholder: $form-field-placeholder;
$form-multiselect-tags-container-background: $form-field-background;
$form-multiselect-tags-container-border: $form-field-border;
$form-multiselect-tags-container-border-focus: $form-field-border;

$form-multiselect-options: $color-16;
$form-multiselect-options-border: $dropdown-menu-border;
$form-multiselect-options-hover: $dropdown-menu-item-hover;
$form-multiselect-options-active: $dropdown-menu-item-active;
$form-multiselect-options-background: $dropdown-menu-item-background;
$form-multiselect-options-background-hover: $dropdown-menu-item-background-hover;
$form-multiselect-options-background-active: $dropdown-menu-item-background-active;

$pagination-navigation: $color-16;
$pagination-navigation-page-field: $color-16;
$pagination-navigation-page-field-background: $color-10;
$pagination-navigation-page-field-border: $color-12;

$confirmation-header: $color-10;
$confirmation-header-background: $color-15;
$confirmation-body: $color-16;
