* {
    margin: 0;
    border: 0;
    padding: 0;
}
html {
    height: 100%;
    padding: 0;
}
body {
    line-height: 1.1;
    height: 100%;
    padding: 0;
    max-height: 999999px;
    font-size: 14px;
    font-family: $font-primary;
    font-weight: $weight-regular;
}

main {
    min-height: calc(100% - 130px);
}

.container {
    width:100%;
    min-height: calc(100% - 130px);
    max-width: 100%;
    overflow: hidden;
    @media only screen and (max-width:991px) {
        width:100%;
    }
}
.max-width {
    position: relative;
    max-width: 1350px;
    @include margin-similarities(auto);
}
a {
    text-decoration: none;
    display: block;
    cursor: pointer;
    color: $link;
    &:hover, &:focus{
        text-decoration:none;
        color: $link-hover;
    }
    &:active, &.active {
        color: $link-active;
    }
    &:disabled, &.disabled {
        color: $link-disabled;
    }

    &.a-inline-block {
        display: inline-block;
    }
}

#scrollup {
    background-color: $scrollup-background;
    margin-bottom: 0px;
    margin-right: 45px;
    border-top-left-radius: $footer-form-scroll-button-radius-top-left;
    border-top-right-radius: $footer-form-scroll-button-radius-top-right;
    border-bottom-left-radius: $footer-form-scroll-button-radius-bottom-left;
    border-bottom-right-radius: $footer-form-scroll-button-radius-bottom-right;
    display: none;
    height: 60px;
    width: 60px;
    text-align: center;

    i {
        color: $scrollup;
        font-size: 60px;
        font-weight: $weight-bold;
    }

    &.displayed {
        display: block;

        @media only screen and (max-width:480px) {
            display: none;
        }
    }

    &:hover {
        background-color: $scrollup-background-hover;

        i {
            color: $scrollup-hover;
        }
    }
}

h1 {
    font-size: 3.143em;
    line-height: 58px;
    @media only screen and (max-width:480px) {
        font-size:2.786em;
    }
}

h1,
h3,
h2,
h4,
h5,
h6 {
    font-weight: $weight-bold;
    margin: 0;
}
ul,
li {
    margin: 0;
    list-style-type: none;
}
img {
    border: 0;
    display: block;
}
p {
    margin: 0;
    padding: 0;
}
.clearfix {
    clear: inherit;
    &:after {
        clear: both;
        content: ' ';
        font-size: 0;
        @include height-lineheightsimilarities(0px);
        visibility: hidden;
        width: 0;
        display: block;
    }
}
.col-sm-12,
.col-xs-12 {
    float: none;
    padding:0;
}
.col-sm-12,
.col-sm-8,
.col-sm-6,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col-md-12,
.col-md-8,
.col-md-6,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-lg-12,
.col-lg-8,
.col-lg-6,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-sm-12,
.col-sm-8,
.col-sm-6,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1 {
    padding:0;
    margin:0;
    flex:none;
    float:left;
}
.row {
    @include margin-similarities(0);
}
.qs {
    background-color: $tooltip-background;
    @include border-radius(2px);
    color: $tooltip;
    padding:5px 10px;
    font-size: 0.750em;
}
#icons {
    font-size:40px;
}
table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}
/* TODO FIXME : font-awesome ne fonctionne pas car surchage type avec Roboto */
.fas, .fal, .far {
    font-family: 'fontawesome-webfont';
}

.alert-container {
    position: fixed;
    width: 40%;
    z-index: 9999;
    margin: auto;
    left: 0;
    right: 0;
    top: 65px;

    @media only screen and (max-width:1024px) {
        top: 55px;
        width: 70%;
    }
}

.alert {
    width: 100%;
    margin: auto;
    line-height: 1.5;
    margin-bottom: 5px;
    font-size: 0.90rem;
    button {
        height: 100%;
        &:focus,
        &.active,
        &:hover {
            background-color: transparent;
        }
    }

    a, a:hover {
        color: inherit;
        text-decoration: underline;
        display: inline-block;
    }
}

.tooltip {
    .tooltip-inner {
        color: $tooltip;
        background-color: $tooltip-background;
    }
}


.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $tooltip-background;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: $tooltip-background;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: $tooltip-background;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: $tooltip-background;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
